<template>
	<DialogPV
		v-model:visible="isVisible"
		style="width: 450px; height: 760px"
		modal
		header="Exchange event"
	>
		<div>
			<label
				for="sourcevaluta"
				class="me-2 ms-2 mb-2">Source valuta
				<div style="height: 2px;" />
				<div
					id="sourcevaluta"
					class="container">
					<div
						v-for="item in getValuta"
						:key="item.id"
						class="ms-2 me-2">
						<RadioButton
							v-model="exchange.sourcevaluta"
							:inputId="`s${item.name}`"
							name="pizza"
							:invalid="exchange.sourcevaluta === ''"
							:value="item.name" />
						<label
							:for="`s${item.name}`"
							class="me-2 ms-2">{{ item.name }}</label>
					</div>
				</div>
			</label>
			<div style="height: 2px;" />
			<label
				for="targetvaluta"
				class="me-2 ms-2">Target valuta
				<div class="container">
					<div
						v-for="item in getValuta"
						id="targetvaluta"
						:key="item.id"
						class="ms-2 me-2">
						<RadioButton
							v-model="exchange.targetvaluta"
							:inputId="`t${item.name}`"
							:invalid="exchange.targetvaluta === ''"
							name="pizza"
							:value="item.name" />
						<label
							:for="`t${item.name}`"
							class="me-2 ms-2">{{ item.name }}</label>
					</div>
				</div>
			</label>
			<FloatLabel>
				<InputText
					id="sourcesum"
					v-model="exchange.sourcesum"
					:invalid="!chkSourcesum()"
					style="width: 390px;" />
				<label for="sourcesum">Source sum</label>
			</FloatLabel>
			<div style="height: 30px;" />
			<FloatLabel>
				<InputText
					id="targetsum"
					v-model="exchange.targetsum"
					:invalid="!chkTargetsum()"
					style="width: 390px;" />
				<label for="targetsum">Target sum</label>
			</FloatLabel>
			<div style="height: 30px;" />
			<FloatLabel>
				<DatePicker
					id="exDate"
					v-model="exchange.exDate"
					style="width: 390px;" />
				<label for="exDate">Exchange date</label>
			</FloatLabel>
			<div style="height: 30px;" />
			<FloatLabel>
				<InputText
					id="notelabel"
					v-model="exchange.note"
					:invalid="!chkNote()"
					style="width: 390px;" />
				<label for="notelabel">Note</label>
			</FloatLabel>
			<div style="height: 30px;" />
		</div>
		<template #footer>
			<span class="dialog-footer">
				<ButtonPV
					label="Cancel"
					class="me-4 ms-4"
					severity="secondary"
					@click="this.setDialogFormVisible(false)" />
				<ButtonPV
					label="Confirm"
					severity="Primary"
					:disabled="!validate()"
					@click="formValidate(exchange)"
				/>
			</span>
		</template>
	</DialogPV>
</template>
<script>

import { mapGetters, mapMutations } from 'vuex';
import ButtonPV from 'primevue/button';
import DialogPV from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import FloatLabel from 'primevue/floatlabel';
import DatePicker from 'primevue/datepicker';
import RadioButton from 'primevue/radiobutton';

// eslint-disable-next-line import/extensions, import/no-unresolved
import utils from '@/utils/utils';

export default {
	components: {
		DialogPV,
		RadioButton,
		DatePicker,
		FloatLabel,
		InputText,
		ButtonPV
	},
	data() {
		return {
			ingredient: null,
			formLabelWidth: '140px',
			exchange: {
				targetvaluta: '',
				sourcevaluta: '',
				sourcesum: '',
				targetsum: '',
				exDate: utils.getDateNow(),
				note: ''
			},
			rules: {
				targetvaluta:
					[{ required: true, message: 'Please select target valuta', trigger: 'change' }],
				sourcevaluta:
					[{ trigger: 'change', required: true, message: 'Please select target valuta' }],
				targetsum:
					[{
						validator: this.validateFloatInput,
						trigger: 'blur',
						required: true,
						message: ''
					}],
				sourcesum:
					[{
						validator: this.validateFloatInput,
						trigger: 'blur',
						required: true,
						message: ''
					}]
			}
		};
	},
	computed: {
		...mapGetters(
			'base',
			[
				'getValuta'
			]
		),
		...mapGetters(
			'accaunt',
			[
				'getDialogFormVisible'
			]
		),
		isVisible: {
			get() {
				return this.getDialogFormVisible;
			},
			set(value) {
				this.setDialogFormVisible(value);
			}
		}
	},
	methods: {
		chkSourcesum() {
			return utils.checkFloatAndZero(this.exchange.sourcesum);
		},
		chkTargetsum() {
			return utils.checkFloatAndZero(this.exchange.targetsum);
		},
		chkNote() {
			return utils.checkMemoNotEmpty(this.exchange.note);
		},
		...mapMutations(
			'accaunt',
			[
				'setDialogFormVisible',
				'pushToAccGridData'
			]
		),
		validateFloatInput(rule, value, callback) {
			if (value === '') {
				callback(new Error('Please input target sum. How much money did you exchange.'));
			} else if (!utils.checkFloat(value)) {
				callback(new Error('sum must be a number!'));
			} else {
				callback();
			}
		},
		validate() {
			if (this.exchange.targetvaluta === '') return false;
			if (this.exchange.sourcevaluta === '') return false;
			if (!utils.checkFloatAndZero(this.exchange.sourcesum)) return false;
			if (!utils.checkFloatAndZero(this.exchange.targetsum)) return false;
			if (!utils.checkMemoNotEmpty(this.exchange.note)) return false;
			return true;
		},
		formValidate() {
			if (this.validate()) {
				this.setDialogFormVisible(false);
				this.pushToAccGridData([{
					id: `new${Date.now()}`,
					amount: this.exchange.targetsum,
					creditspent: 'Credit',
					goal: 'Exchange',
					derection: 'Business',
					dateofpayment: this.exchange.exDate,
					dateofrecord: utils.getDateNow(),
					valuta: this.exchange.targetvaluta,
					walletamount: '',
					edited: true,
					support: 1,
					note: this.exchange.note,
					action: 'BAR/Restaurant'
				}]);
				this.pushToAccGridData([{
					id: `new${Date.now()}`,
					amount: this.exchange.sourcesum,
					creditspent: 'Spent',
					goal: 'Exchange',
					derection: 'Business',
					dateofpayment: this.exchange.exDate,
					dateofrecord: utils.getDateNow(),
					valuta: this.exchange.sourcevaluta,
					walletamount: '',
					edited: true,
					support: 1,
					note: this.exchange.note,
					action: 'BAR/Restaurant'
				}]);
			}
		}
	}
};
</script>
<style scoped>
.container{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	border: 1px solid black;
	padding: 10px;
}
</style>
