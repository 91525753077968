import { ElMessage, ElMessageBox } from 'element-plus';
import MessagePV from 'primevue/message';
// eslint-disable-next-line import/extensions, import/no-unresolved
import utils from '@/utils/utils';
// eslint-disable-next-line import/no-cycle, import/extensions, import/no-unresolved
import * as nimdaEmpApi from '@/api/nimdaEmp';

export default {
	namespaced: true,
	state: {
		empSalaryCycle: // за какое время выплачивается з/п
		[
			{ value: 0, label: 'Hour' },
			{ value: 1, label: 'Day' },
			{ value: 3, label: 'Week' },
			{ value: 4, label: 'Month' }
		],
		EmpEditId: 0,
		staffList: [], // список работников с параметрами
		isStaffLoaded: false, // сотрудники загружено или нет
		staffSalaryData: {
			daysSalary: [],
			cashAdvance: 0,
			deducted: 0,
			newCashAdvance: 0
		}, // данные о зарплате сотрудников
		openTimeWorcDial: false, // открывает диалог с рабочим временем
		currentEmployeeId: 0, // какое ид работника сейчас редактируется
		currentEmployeData: {}, // row с параметрами работника
		loadedEmployeeSalaryByDay: false, // флаг загрузки массива зарплаты
		employeeSalaryByDay: [], // массив зарплаты работников
		employeTableCol: [], // массив колонок для таблицы работников
		employeTableColLoaded: false, // состояние массива колонок загружен или нет
		showAddCard: false,
		employeFromSelect: '', // выделенный работник
		statisticsStartDate: '', // дата начала статистики
		statisticsFinishDate: '', // дата окончания статистики работника
		statisticsEmpSalary: [], // массив статистики работника для окна статистики
		statisticsEmpSalaryCol: [], // массив статистики
		statisticsEmpSalaryColLoaded: false, // массив статистики загружен/незагружен
		DismissEmp: false, // уволеный работник
		empForEdit: {}, // работник для редактирования
		showEmpEditDial: false, // показать диалог с работником
		isSalaryCalcDialOpen: false, // флаг открытия диалогового окна для подсчета зарплаты
		showCashAdvanceDialog: false, // флаг открытия диалогового окна для аванса
		statisticsDrawer: false, // открыть статистик Drawer
		empInfWinShow: false // окно информации работника
	},
	getters: {
		getEmpSalaryByToDate: state => (id) => {
			const time = utils.strtotime(
				`${utils.date('Y-m-d', utils.time())} 15:00:00`
			);
			return state.employeeSalaryByDay.find(el => el.employeId === id && el.date === time);
		},
		getEmptyStaffSalaryData: state => state.staffSalaryData.daysSalary,
		getOpenTimeWorcDial: state => state.openTimeWorcDial,
		getEmpInfWinShow: state => state.empInfWinShow,
		getEmpForEdit: state => state.empForEdit,
		getShowAddCard: state => state.showAddCard,
		getShowEmpEditDial: state => state.showEmpEditDial,
		getEmpEditId: state => state.EmpEditId,
		getDismissEmp: state => state.DismissEmp,
		calculateTotalSalaryAmount: (state) => {
			let totalAmount = 0;
			state.statisticsEmpSalary.forEach((obj) => {
				totalAmount += obj.amount;
			});
			return totalAmount;
		},
		getStatisticsEmpSalaryCol: state => state.statisticsEmpSalaryCol,
		getStatisticsEmpSalaryColLoaded: state => state.statisticsEmpSalaryColLoaded,
		getStatisticsEmpSalary: state => state.statisticsEmpSalary,
		getEmployeeSalaryByDay: state => state.employeeSalaryByDay,
		getLoadedEmployeeSalaryByDay: state => state.loadedEmployeeSalaryByDay,
		getStatisticsStartDate: state => state.statisticsStartDate,
		getStatisticsFinishDate: state => state.statisticsFinishDate,
		getEmployeFromSelect: state => state.employeFromSelect,
		getStatisticsDrawer: state => state.statisticsDrawer,
		getshowCashAdvanceDialog: state => state.showCashAdvanceDialog,
		getEmployeTableCol: state => state.employeTableCol,
		getEmployeSalaryDay: state => (day, id) => {
			const fItem = state.employeeSalaryByDay.find(el => el.date === day && el.employeId === id);
			if (fItem === undefined) return false;
			return fItem;
		},
		getEmployeBySelAccCode: (state) => {
			const id = state.employeFromSelect;
			if (id !== '') {
				return state.staffList.filter(record => record.id === id)[0].accCode;
			}
			return false;
		},
		getNextDayOfSalary: state => (id) => {
			const userSalaryDays = state.employeeSalaryByDay.filter(record => record.employeId === id);
			if (!userSalaryDays.length) return false;
			return userSalaryDays.reduce((youngest, current) => {
				if (current.date < youngest.date && !current.paid) {
					return current;
				}
				return youngest;
			}, userSalaryDays[0]);
		},
		getLoadEmployees: state => state.isStaffLoaded,
		getIsNotSaved: state => state.staffList.some(item => !item.saved),
		getEmployeesSalary: state => state.staffSalaryData.daysSalary,
		getEmployeCashAdvance: state => state.staffSalaryData.cashAdvance,
		getEmployeDeducted: state => state.staffSalaryData.deducted,
		getEmployeNewCashAdvance: state => state.staffSalaryData.newCashAdvance,
		getCurrentEmployeData: state => state.currentEmployeData,
		getDialogCalcId: state => state.currentEmployeeId,
		getIsSalaryCalcDialOpen: state => state.isSalaryCalcDialOpen,
		getEmpSalaryCycle: state => state.empSalaryCycle,
		getEmpSalaryCycleId: state => val => state.empSalaryCycle.find(({ label }) => label === val),
		getEmployeEditedCell: state => state.EmployeEditedCell,
		getEmployees: state => state.staffList,
		getEmployeesById: state => (id) => {
			const fItem = state.staffList.find(el => el.id === id);
			if (fItem === undefined) return false;
			return fItem;
		},
		CalcSumSalary: state => utils.number_format(state.staffSalaryData.daysSalary.reduce((sum, elem) => sum + elem.daySalary, 0), 2, '.', ' '),
		CalcExtraDaySalary: state => utils.number_format(state.staffSalaryData.daysSalary.reduce((sum, elem) => sum + elem.extraTime * elem.extraTimeSalary, 0), 2, '.', ' '),
		CalcTime: state => utils.number_format(state.staffSalaryData.daysSalary.reduce((sum, elem) => sum + elem.time, 0), 2, '.', ' '),
		CalcExtraTime: state => utils.number_format(state.staffSalaryData.daysSalary.reduce((sum, elem) => sum + elem.extraTime, 0), 2, '.', ' '),
		CalcTotalDaySalary: state => utils.number_format(state.staffSalaryData.daysSalary.reduce((sum, elem) => sum + (elem.extraTime * elem.extraTimeSalary) + elem.daySalary, 0), 2, '.', ' '),
		CalcDays: (state, getters) => Math.floor(getters.CalcAllSecond / (8 * 3600)),
		CalcHours: (state, getters) => Math.floor(
			(getters.CalcAllSecond - getters.CalcDays * 8 * 3600) / 3600
		),
		CalcMin: (state, getters) => Math.floor(
			(getters.CalcAllSecond - ((getters.CalcDays * 3600 * 8) + getters.CalcHours * 3600)) / 60
		),
		CalcAllSecond: state => state.staffSalaryData.daysSalary.reduce((sum, el) => (
			!el.checkBoxses.includes('dayoff') ? sum + (el.dates[1] / 1000 - el.dates[0] / 1000) : sum), 0),
		CalcDates: (state) => {
			const dates = state.staffSalaryData.daysSalary.map(el => el.dateToDay * 1000);
			// переводим unix time в миллисекунды
			const oldestDate = Math.min(...dates); // находим самую старую дату
			const newestDate = Math.max(...dates); // находим самую новую дату
			return `${utils.date('d M', oldestDate / 1000, ' ', ' ')} - ${utils.date('d M', newestDate / 1000, ' ', ' ')}`;
		}
	},
	mutations: {
		setOpenTimeWorcDial(state, v) {
			state.openTimeWorcDial = v;
		},
		setEmpInfWinShow(state, v) {
			state.empInfWinShow = v;
		},
		setEmpForEdit(state, v) {
			state.empForEdit = v;
		},
		setEmpEditId(state, v) {
			state.EmpEditId = v;
		},
		// состояние окна добавления карты для сотрудника
		setShowAddCard(state, v) {
			state.showAddCard = v;
		},
		// переносит даные по сотрудникам в массив их хранения
		setCurrentEmployeData(state, v) {
			state.currentEmployeData = v;
		},
		// текущий сотрудник для редактирования и еще чего-нибудь
		setDialogCalcId(state, v) {
			state.currentEmployeeId = v;
		},
		// состояние окна калькуляции зп
		setIsSalaryCalcDialOpen(state, v) {
			state.isSalaryCalcDialOpen = v;
		},
		// состояние окна редактирования и добавления сотрудика
		setShowEmpEditDial(state, { edit, id }) {
			state.showEmpEditDial = edit;
			state.EmpEditId = id;
			if (id !== 0 && edit) {
				console.log(id, edit);
				const fItem = state.staffList.find(el => el.id === id);
				if (fItem === undefined) return;
				state.empForEdit.id = id;
				// state.empForEdit.name = fItem.name;
				// state.empForEdit.midlName = fItem.midlName;
				// state.empForEdit.lastName = fItem.lastName;
				// state.empForEdit.salary = fItem.salary;
				// state.empForEdit.extraTimeSalary = fItem.extraTimeSalary;
				// state.empForEdit.perDayOrHours = fItem.perDayOrHours;
			}
		},
		// удаляет сотрудника из загруженного списка сотрудников
		deleteEmpFromList(state, id) {
			state.staffList = state.staffList.filter(obj => obj.id !== id);
		},
		// статус окна увольнения сотрудника
		setDismissEmp(state, { b }) {
			state.DismissEmp = b;
		},
		// переносит даные по названиям колонок в массив их хранения
		setStatisticsEmpSalaryCol(state, val) {
			state.statisticsEmpSalaryCol = val;
		},
		// статус загрузки массива статистики
		setStatisticsEmpSalaryColLoaded(state, val) {
			state.statisticsEmpSalaryColLoaded = val;
		}, // переносит даные по зп сотрудника за период в массив хранения
		setStatisticsEmpSalary(state, { val }) {
			state.statisticsEmpSalary = val;
		}, // статус загрузки названиям колонок в массив их хранения
		setEmployeTableColLoaded(state, val) {
			state.employeTableColLoaded = val;
		},
		// хранит то что выбрано в  меню селект (стандартный компонент)
		setEmployeFromSelectM(state, val) {
			state.employeFromSelect = val;
		},
		// рассчет  кешьАдванса и запись его в поле для сотрудника
		setEmployeCashAdv(state, { ca, id }) {
			const fItem = state.staffList.find(el => el.id === id);
			if (fItem === undefined) return;
			fItem.cashAdvance = Number(ca) + Number(fItem.cashAdvance);
		},
		setEmployeData(state, { poleName, id, poleVal }) {
			const fItem = state.staffList.find(el => el.id === id);
			if (fItem === undefined) return;
			fItem.saved = false;
			fItem[poleName] = poleVal;
		},
		// состояние окна кешьАдванса
		setshowCashAdvanceDialog(state, b) {
			state.showCashAdvanceDialog = b.b;
		},
		// запись в массив с хранинием названия колонок таблицы
		setEmployeTableCol(state, date) {
			state.employeTableCol = date;
		},
		updateStaffSalaryDataById(state, { id, newId, paid }) {
			const index = state.staffSalaryData.indexOf(el => el.id === id);
			if (index === -1) return;
			state.staffSalaryData[index].id = newId;
			state.staffSalaryData[index].saved = true;
			state.staffSalaryData[index].paid = paid;
		},
		updateStaffSalaryData(state, serverAns) {
			serverAns.forEach((el) => {
				const index = state.staffSalaryData.daysSalary.indexOf(item => item.id === el.id);
				if (index === -1) return;
				state.staffSalaryData[index].id = el.newId;
				state.staffSalaryData[index].saved = true;
				state.staffSalaryData[index].paid = el.paid;
			});
		},
		updateEmp(state, {
			newCashAdv, lastUp, id, lastUpTime
		}) {
			const fItem = state.staffList.find(el => el.id === id);
			if (fItem === undefined) return;
			fItem.timeOfLastUpdate = lastUp;
			fItem.cashAdvance = newCashAdv;
			fItem.timeOfLastUpdateD = lastUpTime;
			// return true;
		},
		setEmployeDeducted(state, d) {
			state.staffSalaryData.deducted = d;
			state.staffSalaryData.newCashAdvance = state.staffSalaryData.cashAdvance - d;
		},
		// массив сохраненой и не выплаченной зп сотрудника по дням
		setEmployeeSalaryByDay(state, v) {
			state.employeeSalaryByDay = v;
		},
		addEmployeeSalaryByDay(state, row) {
			state.employeeSalaryByDay.push(row);
			console.log('83838383', state.employeeSalaryByDay);
		},
		// установка индикатора загрузки зп сотрудника по дням
		setLoadedEmployeeSalaryByDay(state, val) {
			state.loadedEmployeeSalaryByDay = val;
		},
		setEmptyStaffSalaryData(state, d) {
			state.staffSalaryData.daysSalary = d;
		},
		addEmployeesSalary(state, row) {
			state.staffSalaryData.daysSalary.push(row);
		},
		setEmployeesCashAdvance(state, d) {
			state.staffSalaryData.cashAdvance = d;
			state.staffSalaryData.newCashAdvance = d;
		},
		addEmploye(state, row) {
			if (row.id !== 0) {
				const indexToRemove = state.staffList.findIndex(item => item.id === row.id);
				row.saved = false;
				if (indexToRemove !== -1) state.staffList.splice(indexToRemove, 1, row);
				return;
			}
			if (row.id === 0) row.id = utils.getNewId();
			state.staffList.push(row);
		},
		setEmployeEditedCell(state, v) {
			state.EmployeEditedCell = v;
		},
		setEmployees: (state, v) => {
			state.staffList = v;
		},
		setLoadedEmployees: function (state, v) {
			state.isStaffLoaded = v;
		},
		CalcDaySalary(state, index) {
			const daysSalary = state.staffSalaryData.daysSalary[index];
			const {
				LBDates,
				dates,
				checkBoxses,
				extraTimeSalary,
				salaryRate,
				salaryPeriod
			} = daysSalary;
			if (checkBoxses.includes('dayoff')) {
				daysSalary.daySalary = 0;
				daysSalary.extraDaySalary = 0;
				daysSalary.extraTime = 0;
				daysSalary.time = 0;
				return;
			}

			const time = Math.abs(dates[1] - dates[0])
			/ 3600000 - Math.abs(LBDates[1] - LBDates[0]) / 3600000;

			if (checkBoxses.includes('halfday')) {
				daysSalary.time = Math.min(time, 4);
				const a = new Date(dates[1]);
				if (a.getHours() === 15) {
					a.setHours(11);
					dates[1] = a;
				}
			} else {
				daysSalary.time = Math.min(time, 8);
				const a = new Date(dates[1]);
				if (a.getHours() === 11) {
					a.setHours(15);
					daysSalary.time = 8;
					dates[1] = a;
				}
			}
			daysSalary.extraTime = Math.max(time - daysSalary.time, 0);
			daysSalary.extraDaySalary = daysSalary.extraTime * extraTimeSalary;
			if (checkBoxses.includes('doublepay')) {
				daysSalary.daySalary = daysSalary.time * salaryRate * 2;
			} else {
				daysSalary.daySalary = daysSalary.time * salaryRate;
			}

			const salaryDivider = {
				Day: 8,
				Week: 40,
				Month: 160
			};

			if (salaryPeriod in salaryDivider) {
				daysSalary.daySalary /= salaryDivider[salaryPeriod];
			}
		},
		setEmployeesByIndex: function (state, { index, nameOfVal, val }) {
			state.staffList[index][nameOfVal] = val;
		},
		updateEmploye(state, arr) {
			arr.forEach((el) => {
				const fItem = state.staffList.find(item => item.id === el.oldID);
				fItem.id = el.id;
				fItem.saved = true;
			});
		},
		setStatisticsDrawer(state, v) {
			state.statisticsDrawer = v;
		},
		setStatisticsStartDate(state, v) {
			state.statisticsStartDate = v;
		},
		setStatisticsFinishDate(state, v) {
			state.statisticsFinishDate = v;
		},
		setEmployeBySel(state, { id, v }) {
			const fItem = state.staffList.filter(record => record.id === id)[0];
			fItem.accCode = v;
			fItem.saved = false;
		}
	},
	actions: {
		addEmployeeSalaryByDay({ commit }, row) {
			commit('addEmployeeSalaryByDay', row);
		},
		addEmployeesSalary({ commit }, row) {
			commit('addEmployeesSalary', row);
		},
		setEmployeBySel({ getters, commit }, v) {
			// загрузает AccCode в работника с id = id
			const id = getters.getEmployeFromSelect;
			if (id !== '' || id !== 0) {
				commit('setEmployeBySel', { id, v });
			}
		},
		showEmpCalcSalary({ commit, getters, dispatch }, { dial, id }) {
			// открывает окно с подсчетом зп
			commit('setIsSalaryCalcDialOpen', dial);
			commit('setDialogCalcId', id);
			if (id === 0) {
				commit('setIsSalaryCalcDialOpen', false);
				return;
			}
			const fItem = getters.getEmployees.find(el => el.id === id);
			if (fItem !== undefined) commit('setCurrentEmployeData', fItem);
			dispatch('makeEmployeesSalary');
		},
		ActStatisticsEmpSalary({ commit }, val) {
			commit('setStatisticsEmpSalary', val);
		},
		CalcDaySalaryA({ commit, getters }, id) {
			// подсчиßтать зп
			console.log('CalcDaySalaryA', id);
			const index = getters.getEmployeesSalary.findIndex(obj => obj.id === id);
			commit('CalcDaySalary', index);
		},
		setStatisticsDate({ commit }, { value, position }) {
			if (position === 'start') commit('setStatisticsStartDate', value);
			if (position === 'finish') commit('setStatisticsFinishDate', value);
		},
		setEmployeFromSelect({ commit }, value) {
			// устанавливает текущего работника
			commit('setEmployeFromSelectM', value);
		},
		startStatistics({ commit }, { value }) {
			// открывает окно статистики
			commit('setStatisticsDrawer', value);
		},
		async loadEmployeSalaryByDay({ rootGetters, getters, commit }) {
			// загружает текущую зп для работников
			if (rootGetters['base/getGlobError'] !== 0 || getters.getLoadedEmployeeSalaryByDay) return;
			const response = await nimdaEmpApi.loadEmployeSalaryByDay();
			if (response.data.error === 0) {
				commit('setEmployeeSalaryByDay', response.data.data);
				commit('setLoadedEmployeeSalaryByDay', true);
			}
		},
		async SaveAndCalcSavary({ getters, dispatch }) {
			getters.getEmployeesSalary.forEach((item) => {
				item.saved = false;
				item.paid = true;
				item.hours = false;
			});
			dispatch('saveEmployeSalaryDays', { calc: true, sum: getters.CalcTotalDaySalary });
		},
		async saveEmployeSalaryDays({
			getters, rootGetters, commit, dispatch
		}, { calc, sum }) {
			const arr = getters.getEmployeesSalary.filter(el => !el.saved);
			if (rootGetters['base/getGlobError'] !== 0 || !arr.length) return;
			arr.forEach((el) => {
				el.dates[2] = el.dates[0].getTime(el.dates[0]) / 1000;
				el.dates[3] = el.dates[1].getTime(el.dates[1]) / 1000;
			});
			const response = await nimdaEmpApi.saveEmployeSalaryDays({
				arr: arr,
				sum: sum,
				empId: getters.getCurrentEmployeData.id,
				dates: getters.CalcDates,
				cashAdvance: getters.getEmployeCashAdvance,
				newCashAdvance: getters.getEmployeNewCashAdvance,
				deducted: getters.getEmployeDeducted,
				calc: calc
			});
			if (response.data.error === 0) {
				commit('updateEmp', {
					newCashAdv: response.data.newCashAdvance,
					lastUp: response.data.lastUpdate,
					id: getters.getCurrentEmployeData.id,
					lastUpTime: response.data.lastUpTime
				});
				dispatch('showEmpCalcSalary', { dial: false, id: 0 });
				commit('updateStaffSalaryData', response.data.data);
			}
		},
		checkCardIdSet({ commit, getters }, id) {
			let str = 'success';
			ElMessageBox.prompt('', '', {
				confirmButtonText: 'OK',
				class: 'w300 set-font',
				cancelButtonText: 'Cancel'
			})
				.then((value) => {
					const arr = getters.getEmployees.filter(
						el => Number(el.accCode) === Number(value.value)
					);
					if (arr.length > 0) {
						if (id !== arr[0].id) {
							str = 'error';
						}
						ElMessage({
							type: str,
							showClose: true,
							message: `Owner of this card ${arr[0].name} ${arr[0].midlName} ${arr[0].lastName}`
						});
					} else MessagePV({ type: 'warning', message: 'Nothing found' });
				})
				.catch(() => {
					ElMessage({ type: 'warning', message: 'Editing canceled' });
				});
			// const arr = getters.getEmployees.filter(el => !el.saved);
			if (id === 500) commit('updateStaffSalaryData', 1);
		},
		async saveEmploye({ rootGetters, getters, commit }, newEmp) {
			if (newEmp !== undefined) commit('addEmploye', newEmp);
			const arr = getters.getEmployees.filter(el => !el.saved);
			if (rootGetters['base/getGlobError'] !== 0 || !arr.length) return;
			const response = await nimdaEmpApi.saveEmploye(arr);
			if (response.data.data[0].error === 0) {
				ElMessage({ type: 'success', message: 'All changes were successfully saved' });
				commit('updateEmploye', response.data.data);
				commit('setShowEmpEditDial', { edit: false, id: '99999997d88888' });
			}
		},
		async saveEmployeCode({ dispatch }, val) {
			dispatch('setEmployeBySel', val);
			dispatch('saveEmploye');
			// commit('setShowAddCard');
		},
		makeEmployeesSalary({ getters, commit, dispatch }) {
			const { getCurrentEmployeData } = getters;

			let lastSalaryDay = getCurrentEmployeData.timeOfLastUpdateD;

			const daysWOsalary = Math.max(Math.floor((utils.time() - lastSalaryDay) / (3600 * 24)), 0);
			if (daysWOsalary === 0) {
				ElMessage({ type: 'warning', message: 'Salaries have been paid for all days, there are no unpaid days.', showClose: true });
				dispatch('showEmpCalcSalary', { dial: false, id: 0 });
				return;
			}

			commit('setEmptyStaffSalaryData', []);
			commit('setEmployeesCashAdvance', getCurrentEmployeData.cashAdvance);
			for (let i = 0; i < daysWOsalary; i++) {
				lastSalaryDay += 3600 * 24;
				const timeoOfStartWork = new Date(lastSalaryDay * 1000);
				timeoOfStartWork.setHours(7, 0, 0);
				const savedSalary = getters.getEmployeSalaryDay(lastSalaryDay, getCurrentEmployeData.id);
				const datenew = savedSalary ? [savedSalary.date1 * 1000, (
					savedSalary.date2 === 0 ? (savedSalary.date1 + 60) : savedSalary.date2
				) * 1000] : [(timeoOfStartWork.getTime()), timeoOfStartWork.getTime() + 3600000 * 8];
				datenew[0] = new Date(datenew[0]);
				datenew[1] = new Date(datenew[1]);
				const salaryData = {
					id: i,
					info: savedSalary.info === undefined ? ['', 0] : savedSalary.info,
					empId: getCurrentEmployeData.id,
					date: timeoOfStartWork.getDate(),
					mouth: utils.getMonthName(timeoOfStartWork.getMonth()),
					dayOfWeek: String(timeoOfStartWork).split(' ')[0],
					dates: datenew,
					daySalary: savedSalary ? savedSalary.daySalary : 0,
					salaryRate: getCurrentEmployeData.salary,
					salaryPeriod:
						savedSalary ? savedSalary.salaryPeriod : getCurrentEmployeData.perDayOrHours,
					extraTimeSalary: getCurrentEmployeData.extraTimeSalary,
					realId: savedSalary ? savedSalary.id : 0,
					dateToDay: lastSalaryDay,
					checkBoxses: savedSalary ? savedSalary.checkBoxses : ['dayoff'],
					saved: false,
					paid: false,
					LBDates:
						savedSalary
							? [savedSalary.lanchBreakDate1 * 1000, savedSalary.lanchBreakDate2 * 1000]
							: [0, 0],
					LunchBreakTime: savedSalary
						? (savedSalary.lanchBreakDate2 - savedSalary.lanchBreakDate1) / 3600
						: 0,
					extraTime: savedSalary ? savedSalary.extraTime : 0,
					time: 0,
					hours: false
				};
				commit('addEmployeesSalary', salaryData);
				dispatch('CalcDaySalaryA', i);
			}
		},
		setEmployeById({ getters, commit }, { id, nameOfVal, val }) {
			const index = getters.getEmployees.findIndex(el => el.id === id);
			if (index !== -1) {
				commit('setEmployeesByIndex', { index: index, nameOfVal: nameOfVal, val: val });
				commit('setEmployeesByIndex', { index: index, nameOfVal: 'saved', val: false });
			}
		},
		setSelectEmployeById({ getters, commit }, { id, nameOfVal, val }) {
			const index = getters.getEmployees.findIndex(el => el.id === id);
			if (index !== -1) {
				const { label } = getters.getEmpSalaryCycle.find(({ value }) => value === val);
				commit('setEmployeesByIndex', { index: index, nameOfVal: nameOfVal, val: label });
				commit('setEmployeesByIndex', { index: index, nameOfVal: 'saved', val: false });
			}
		},
		async loadEmploye({ rootGetters, getters, commit }) {
			if (rootGetters['base/getGlobError'] !== 0 || getters.getLoadEmployees) return;
			const response = await nimdaEmpApi.loadEmploye();
			if (response.data.error === 0) {
				commit('setEmployees', response.data.data);
				commit('setLoadedEmployees', true);
			}
		},
		async loadColunmEmpSalary({ rootGetters, commit, getters }) {
			if (rootGetters['base/getGlobError'] !== 0 || getters.getStatisticsEmpSalaryColLoaded) return;
			const response = await nimdaEmpApi.loadColunmEmpSalary();
			if (response.data.error === 0) {
				commit('setStatisticsEmpSalaryCol', response.data.data);
				commit('setStatisticsEmpSalaryColLoaded', true);
			}
		},
		async loadNimdaEmployeCol({ rootGetters, commit, getters }) {
			if (rootGetters['base/getGlobError'] !== 0 || getters.getEmployeTableColLoaded) return;
			const response = await nimdaEmpApi.loadColunm();
			if (response.data.error === 0) {
				console.log('response.data', response.data);
				commit('setEmployeTableCol', response.data.data);
				commit('setEmployeTableColLoaded', true);
			}
		},
		async DismissEmp({ rootGetters, commit }, { id }) {
			if (rootGetters['base/getGlobError'] !== 0) return;
			const response = await nimdaEmpApi.DismissEmp({ id: id });
			if (response.data.error === 0) {
				commit('setDismissEmp', { b: false });
				commit('deleteEmpFromList', response.data.id);
				// commit('setEmployeCashAdv', { ca: response.data.ca, id: response.data.id });
			}
		},
		async addCashAdvanceEmp({ rootGetters, commit }, { value, sum }) {
			if (rootGetters['base/getGlobError'] !== 0) return;
			const response = await nimdaEmpApi.addCashAdvanceEmp({ sum: sum, idEmp: value });
			if (response.data.error === 0) {
				commit('setEmployeCashAdv', { ca: response.data.ca, id: response.data.id });
				commit('setshowCashAdvanceDialog', { b: false });
			}
		},
		async loadStatisticEmploye({ rootGetters, getters, commit }) {
			if (rootGetters['base/getGlobError'] !== 0) return;
			const response = await nimdaEmpApi.loadStatisticEmploye(
				getters.getEmployeFromSelect,
				getters.getStatisticsStartDate,
				getters.getStatisticsFinishDate
			);
			if (response.data.error === 0) {
				commit('setStatisticsEmpSalary', { val: response.data.data });
			}
		}
	}
};
